<template>
    <header class="overflow-hidden pb-3">
        <Transition name="logo-slide-in" appear>
            <h1 class="logo" style="transition-delay: 0.3s;">ko<span class="logo-v color-primary">v</span>alson</h1>
        </Transition>
    </header>
</template>

<style scoped>
@keyframes logo-v-pulse {
    0% {
        transform: scale(0.95);
        text-shadow: 0 0 0 rgba(229, 62, 62, 0.7);
    }

    70% {
        transform: scale(1);
        text-shadow: 0 0 30px rgba(229, 62, 62, 0);
    }

    100% {
        transform: scale(0.95);
        text-shadow: 0 0 0 rgba(229, 62, 62, 0);
    }
}

.logo-slide-in-enter-active,
.logo-slide-in-leave-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 1s cubic-bezier(0.5, 0.5, 0, 1);
}

.logo-slide-in-enter-from,
.logo-slide-in-leave-to {
    opacity: 0;
    transform: translateY(100%);
}

.logo {
    color: var(--bs-dark);
    font-size: 6rem;
    font-weight: normal;
    margin: 0;
}

.logo-v {
    animation: logo-v-pulse 2s infinite;
}
</style>
