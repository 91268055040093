<template>
    <div class="container d-flex flex-column h-100 text-center py-3">
        <div class="main-wrapper my-auto pb-5">
            <Home />
        </div>
    </div>
</template>

<script lang="ts">
import Home from "@/views/Home.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "App",
    components: {
        Home,
    },
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

*:not(.fas) {
    font-family: "Quicksand", sans-serif;
}

.fas {
    font-family: "Font Awesome 5 Free", sans-serif;
    font-weight: 900;
}

:root {
    --primary: #e53e3e;
    --bezier: cubic-bezier(0.5, 0.5, 0, 1);
}

html,
body,
#app {
    height: 100%;
}

.main-wrapper {
    transition: height 0.5s var(--bezier);
}

.color-primary {
    color: var(--primary);
}
</style>
