<template>
    <div>
        <Logo />
        <Separator />
        <Navbar />
    </div>
</template>

<script lang="ts">
import Logo from "@/components/Logo.vue";
import Navbar from "@/components/Navbar.vue";
import Separator from "@/components/Separator.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Home",
    components: {
        Logo,
        Navbar,
        Separator,
    },
});
</script>
