
import Home from "@/views/Home.vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "App",
    components: {
        Home,
    },
});
