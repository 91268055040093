<template>
    <nav class="fs-5 overflow-hidden pt-3" role="navigation">
        <Transition name="navbar-slide-in" appear>
            <ul class="nav justify-content-center" style="transition-delay: .9s;">
                <li class="nav-item">
                    <a target="_blank" href="https://blog.krzysztof.tatarynowicz.eu" class="nav-link dropdown-item">
                        <div class="contents-wrap">Blog</div>
                    </a>
                </li>
                <li class="nav-item">
                    <a target="_blank" href="https://gitlab.com/kovalson" class="nav-link dropdown-item">
                        <div class="contents-wrap">
                            <i class="fab fa-gitlab fa-fw me-1"></i>Gitlab
                        </div>
                    </a>
                </li>
                <li class="nav-item">
                    <a target="_blank" href="https://www.linkedin.com/in/krzysztof-tatarynowicz" class="nav-link dropdown-item">
                        <div class="contents-wrap">
                            <i class="fab fa-linkedin fa-fw me-1"></i>LinkedIn
                        </div>
                    </a>
                </li>
            </ul>
        </Transition>
    </nav>
</template>

<style scoped>
@keyframes nav-link-border-scale {
    0% {
        height: 0.5rem;
        transform: translateY(0.25rem);
    }
    100% {
        height: 0.1rem;
        transform: translateY(0);
    }
}

.nav-link > .contents-wrap {
    transition: all 100ms var(--bezier);
}

.nav-link:hover {
    color: var(--primary);
    background: none;
}

.nav-link:hover > .contents-wrap {
    transform: translateY(-0.15em);
}

.nav-link.active {
    color: var(--primary) !important;
    border: none !important;
    background: none;
}

.nav-link:active,
.nav-link:focus {
    color: var(--primary) !important;
    background: none;
}

.nav > .nav-item > .nav-link.active {
    color: var(--bs-primary);
    border-bottom: 1px solid var(--bs-primary);
}

.navbar-slide-in-enter-active,
.navbar-slide-in-leave-active {
    opacity: 1;
    transform: translateY(0);
    transition: all .75s cubic-bezier(0.5, 0.5, 0, 1);
}

.navbar-slide-in-enter-from,
.navbar-slide-in-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}

.fa-gitlab {
    color: #fc6d26;
}

.fa-linkedin {
    color: #0a66c2;
}
</style>
