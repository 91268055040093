<template>
    <div class="w-75 mx-auto">
        <Transition name="horizontal-bar-expand" appear>
            <hr class="my-0 mx-auto rounded">
        </Transition>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "Separator",
});
</script>

<style scoped>
.horizontal-bar-expand-enter-active,
.horizontal-bar-expand-leave-active {
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 1px;
    transform: translateY(0);
    transition: width 1s cubic-bezier(0.5, 0.5, 0, 1),
        color 1s cubic-bezier(0.9, 1, 0.1, 1),
        height 1s cubic-bezier(0.5, 0.5, 0, 1),
        transform 1s cubic-bezier(0.5, 0.5, 0, 1);
}

.horizontal-bar-expand-enter-from,
.horizontal-bar-expand-leave-to {
    color: var(--primary) !important;
    width: 0;
    height: 16px !important;
    transform: translateY(-8px);
}
</style>
